@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-5xl font-semibold;
    }

    h2 {
        @apply text-4xl font-semibold;
    }

    h3 {
        @apply text-2xl font-semibold;
    }

    h4 {
        @apply text-xl font-semibold;
    }

    h5 {
        @apply text-lg font-semibold;
    }

    .bodyTitle {
        font-size: 42px;
        line-height: 54px;
        font-weight: 300;
    }

    .body1 {
        font-size: 24px;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.5px;
    }

    .body2 {
        font-size: 18px;
        font-weight: 400;
        line-height: 155%;
    }

    .body3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 170%;
    }

    .eyebrown {
        font-size: 14px;
        font-weight: 400;
        line-height: 126%;
        text-transform: uppercase;
    }

    .btn {
        transform: none !important;
    }

    /*a {*/
    /*  @apply text-primary*/
    /*}*/

    /*p {*/
    /*  @apply text-blue-for-body-text*/
    /*}*/
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
html {
    font-family: 'Roboto', sans-serif;
}
